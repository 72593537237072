// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

@font-face {
  font-family: M19_Font;
  src: url('./assets/fonts/Gerbera-W04-Bold.ttf');
}

@font-face {
  font-family: M19Bold;
  src: url('./assets/fonts/Gotham-Bold.otf');
}

@font-face {
  font-family: M19Normal;
  src: url('./assets/fonts/GothamBook.ttf') format("truetype");
}

@font-face {
  font-family: M19NormalItalic;
  src: url('./assets/fonts/GothamBookItalic.ttf');
}

@font-face {
  font-family: M19Thin;
  src: url('./assets/fonts/Gotham-Thin.otf');
}

@font-face {
  font-family: M19ThinItalic;
  src: url('./assets/fonts/Gotham-ThinItalic.otf');
}

:root {
  --bg-color:  #f8f5f5;
  --M19Primary: #D33E3E;
  --M19Secundary: #5E4F4F;
  --M19Alternative: #EEEEEE;
  --M19Invert: #353535;
  --M19PrimaryHover: #5C0000;
  --titleTextColor: #910000;
  --subtitleTextColor: #2C2B2B;
  --textPrimaryColor: #414141;
  --textLightColor: #999999;
  --textItemsColor: white;
  --lightColor: white;
  --backgroundColor: #FAFAFA;
  --titleFont: "M19_Font", "Arial", sans-serif;
  --generalFont: "M19Normal", "Arial", sans-serif;
  --defaultTextSize: 1rem;
  --primaryBlue: #0259bd;
  --primaryPurple: #70069e;
  --primaryGreen: #02bd54;
  --primaryYellow: #F8BA43;
  --primaryOrange: #f87c43;
  --primaryRed: #D94555;
}

// * {
//   font-family: M19Normal, sans-serif;
// }

body {
  background-color: var(--backgroundColor);
}

.sweetActions {
  gap: 10px;
}

.sweetInput {
  padding: 12px 20px !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

@media (min-width: 1080px) {
  .newYearLogo {
    width: 30%;
  }
}

@media (max-width: 720px) {
  .newYearLogo {
    width: 80%;
  }
}

.newYearBtn {
  background-color: gray;
  border: solid white 3px;
  border-radius: 30px;
  font-size: 1.5rem;
  transition: 0.5s;
}

.newYearBtn:hover {
  background-color: #e0e0e0;
  transition: 0.5s;

  &::before {
    content: 'Continuar';
  }
}

.yearBtn {
  background-color: rgb(255, 255, 255);
  border: solid rgb(255, 76, 76) 3px;
  border-radius: 30px;
  font-size: 1.5rem;
  transition: 0.5s;
}

.yearBtn:hover {
  background-color: #e0e0e0;
  transition: 0.5s;

  &::before {
    content: 'Continuar';
  }
}

///////////////////////////////////////////
// Estilo de text-hint
///////////////////////////////////////////

[data-title] {
  position: relative;
}

[data-title]:after {
  content: attr(data-title);
  background-color: var(--M19Secundary) !important;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  padding: 3px 20px;
  bottom: 110%;
  right: 0;
  left: auto;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  white-space: nowrap;
  border-radius: 3px;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

[data-title]:hover:after {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
}

/////////////////////////////////////////// 

.titleText {
  color: var(--titleTextColor);
  font-size: 1.5rem;
  font-family: var(--titleFont);
  font-weight: bold;
}

.subtitleText {
  background-color: var(--M19Primary);
  color: white;
  font-size: 1.3rem;
  font-family: var(--generalFont);
  border-radius: 0 15px 0 0;
  margin-top: 10px;
  padding: 5px;
}

.buttonsBelt {
  display: flex;
  overflow: scroll;
  gap: 10px;
  margin: 20px 0 20px 0;
}

.goTopBtn {
  &--show {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 10px;
    right: 10px;
    border-radius: 100%;
    background-color: var(--M19Primary);
    border: none;
    outline: none;
    color: var(--textItemsColor);
    transition: 0.5s;
    cursor: pointer;

    &:hover {
      filter: brightness(0.7);
    }
  }

  &--hide {
    display: none;
  }
}

.m19Btn {
  background-color: var(--M19Primary);
  padding: 5px 20px;
  height: fit-content;
  border-radius: 5px;
  outline: none !important;
  border: none !important;
  color: var(--textItemsColor);
  transition: 0.5s;
  white-space: nowrap;

  font: {
    family: var(--fontDefault);
    size: clamp(1rem, 1vw, 4rem);
    weight: bold;
  }

  &:hover {
    background-color: var(--M19PrimaryHover);
  }

  &--UL {
    background-color: var(--primaryGreen);
    padding: 5px;
    border-radius: 5px;
    outline: none !important;
    border: none !important;
    color: var(--textItemsColor);
    transition: 0.5s;
    white-space: nowrap;

    &:hover {
      background-color: #115300;
    }
  }

  &[disabled] {
    background-color: #5E4F4F;
    color: #999999;
  }
}

.m19AltBtn {
  background-color: var(--M19Alternative);
  padding: 5px 20px;
  height: fit-content;
  border-radius: 5px;
  outline: none !important;
  border: none !important;
  color: var(--M19Primary);
  transition: 0.5s;
  white-space: nowrap;

  font: {
    family: var(--fontDefault);
    size: clamp(1rem, 1vw, 4rem);
    weight: bold;
  }

  &:hover {
    background-color: var(--M19Secundary);
    color: white;
  }

  &[disabled] {
    background-color: #5E4F4F;
    color: #999999;
  }
}

.m19InvBtn {
  background-color: var(--M19Invert);
  padding: 5px;
  height: fit-content;
  border-radius: 5px;
  outline: none !important;
  border: none !important;
  color: white;
  transition: 0.5s;
  white-space: nowrap;

  font: {
    family: var(--fontDefault);
    size: clamp(1rem, 1vw, 4rem);
    weight: bold;
  }

  &:hover {
    background-color: #680000;
    color: #ffffff;
  }
}

.m19SelectedBtn {
  filter: brightness(0.5);
}

.actionBtn {
  color: var(--M19Primary);
  transition: 0.5s;
  border: none;
  outline: none;
  border-radius: 100%;
  cursor: pointer;

  &:hover {
    color: var(--M19PrimaryHover);
  }

  &[disabled] {
    color: #999999;
  }
}

.actionAltBtn {
  color: var(--M19Secundary);
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    color: var(--M19Primary);
  }
}

.saveButton {
  position: fixed;
  background-color: var(--M19Primary);
  color: white;
  right: 10px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 9000;
  border: none;
  outline: none;
  transition: 0.5s;

  &:hover {
    background-color: var(--M19PrimaryHover);
  }

  &[disabled] {
    background-color: #5E4F4F;
    color: #999999;
  }
}

.m19Table {
  width: 100%;
  margin: 0.3em;

  thead {
    background-color: var(--M19Primary) !important;
    vertical-align: bottom;

    * {
      padding: 0.8em;
    }

    th {
      color: white;
      font-weight: bold;
    }

  }

  tbody {
    tr {
      height: 50px;

      td {
        padding: 0.5em;
      }

      &:nth-child(even) {
        background-color: #e8ecf0;
      }
    }
  }
}

.m19Tbl {
  width: 100%;
  margin: 0.3em;

  thead {
    background-color: #f5f5f5 !important;
    vertical-align: bottom;

    * {
      padding: 0.8em;
    }

    th {
      font-weight: bold;
    }
  }

  tbody {
    tr {
      height: 50px;

      td {
        padding: 0.5em;
      }

      &:nth-child(even) {
        background-color: #e8ecf0;
      }
    }
  }
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$M19-Inmobiliaria-primary: mat-palette($mat-indigo);
$M19-Inmobiliaria-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$M19-Inmobiliaria-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$M19-Inmobiliaria-theme: mat-light-theme((color: (primary: $M19-Inmobiliaria-primary,
        accent: $M19-Inmobiliaria-accent,
        warn: $M19-Inmobiliaria-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($M19-Inmobiliaria-theme);

/* You can add global styles to this file, and also import other style files*/

@import url("https://fonts.googleapis.com/icon?family=Material+Icons"); //Material Icon
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"); //Bootstrap
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"); //Bootstrap
@import url("https://fonts.googleapis.com/css?family=Roboto:300"); //Fonts

.content {
  min-width: 100%;
  min-height: calc(100vh);
  background-color: #f8f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* active tab */
::ng-deep .mat-tab-label-active {
  color: black !important;
}

.mat-tab-group {
  z-index: 0;
}

*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

//Estilo de card's
.card-form {
  width: 100%;
  padding: 0%;
  margin: 0%;
  background-color: white;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.24), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.mat-drawer-container {
  background-color: #f0eaea;
}

.container-fluid {
  padding: 1%;
}

._card {
  width: 100%;
  padding: 2% 0%;
  margin: 0%;
}

.div-general {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-central {
  width: 70%;
}

//encabezado tabla
.thead-m19 {
  background-color: #d33e3e;
  color: #fff;
}

.thead {
  margin: 0px;
}

mat-icon {
  vertical-align: middle;
}

.m19IconBtn {
  color: #5E4F4F;
  transition: 0.5s;
  cursor: pointer;

  &.primaryBtn {
    color: var(--M19Primary);
  }

  &.altBtn {
    color: white;
  }

  &.infoBtn {
    color: var(--primaryBlue);
  }

  &.positiveBtn {
    color: var(--primaryGreen);
  }

  &.negativeBtn {
    color: var(--primaryRed);
    ;
  }

  &:hover {
    filter: brightness(0.8);
  }
}

.consult {
  width: 50rem;
  margin-left: 0.3cm;
}

.mat-expansion-panel-body {
  padding: 0px 0px 0px;
}

//Tamaño del campo de texto en las ventanas de consulta
.filter {
  padding: 0%;
  width: 40%;
}

/**/
._reports {
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .dialog-responsive {
      max-width: 100vw !important;
      max-height: 90vh;
  }
}